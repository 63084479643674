.footer {
    margin-top: 60px;
    padding: 60px 0;
    line-height: 150%;
    color: var(--white);
    background-color: var(--bg-footer);
}
.footer {
    &__wrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        width: 100%;      
    }
}


@media (max-width: 668px) {
    .footer {
        padding: 16px 0;
        &__wrapper {
            grid-template-columns: repeat(2, 1fr);
        }
        
    }
}

@media (max-width: 460px) {
    .footer {
        padding: 20px;
    }
}

@media (max-width: 403px) {
    .footer {
        &__wrapper {
            grid-template-columns:  1fr;
        }
        
    }
}
