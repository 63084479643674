.Modal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  z-index: -1;
  color: var(--black);
}

.overlay {
  width: 100%;
  height: 100%;
  background: var(--overlay-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  padding: 20px;
  border-radius: 12px;
  background: var(--white);
  transition: 0.3s transform;
  transform: scale(0.5);
  max-width: 40%;
  min-width: 460px;
  text-align: center;

  @media (max-width: 640px) {
    max-width: 100%;
    min-width: 100%;
  }
}

.opened {
  pointer-events: auto;
  opacity: 1;
  z-index: 100;
}

.opened .content {
  transform: scale(1);
}
.isClosing .content {
  transform: scale(0.2);
}
