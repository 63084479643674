.thankYouBox {
    width: 320px;
    padding: 36px 10px;
    margin: 0 auto;
    background-color: #f0f0f0;
    border-radius: 10px;
    text-align: center;
}

.thankYouBox__text {
    color: black;
    font-size: 24px;
}