/* Стили для контейнера слайдера */
.slider-container {
    width: 100%;
    max-width: 512px; /* Максимальная ширина слайда */
    margin: 0 auto; /* Центрирование по горизонтали */
    
    @media (max-width: 568px) {
      max-width: 320px; /* Максимальная ширина слайда */
      
    }
  }
  
  /* Стили для слайдера */
  .slick-slider {
    width: 100%;
  }
  
  /* Стили для слайдов внутри слайдера */
  .slick-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* Высота слайда равна высоте родительского контейнера */
  }
  
  /* Стили для кнопок влево и вправо */
  button {
    margin: 10px; /* Небольшой отступ между кнопками и слайдером */
  }
  