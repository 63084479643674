@import './normalize.css';
@import './reset.css';
@import './colors.css';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');

body {
    margin: 0 auto;
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--white);
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

#root footer {
    margin-top: auto;
}

.stop-scroll {
    overflow: hidden;
}

/* * {
  border: 1px solid coral;
} */