.form {
    display: flex;
    flex-direction: column;
    padding: 0 36px;
    position: relative;
    @media (max-width: 460px) {

        padding: 0;
    }
}

.form__close {
    position: absolute;
    fill: var(--link-contact);
    cursor: pointer;
    right: 0;
}


.payform_tbank {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 2px auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    max-width: 250px;
}

.payform_tbank_row {
    margin: 2px;
    border-radius: 4px;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    border: 1px solid #DFE3F3;
    padding: 15px;
    outline: none;
    background-color: #DFE3F3;
    font-size: 15px;
}

.payform_tbank_row:focus {
    background-color: #FFFFFF;
    border: 1px solid #616871;
    border-radius: 4px;
}

.payform_tbank_btn {
    background-color: #FBC520;
    border: 1px solid #FBC520;
    color: #3C2C0B;
    cursor: pointer;
}

.payform_tbank_btn:hover {
    background-color: #FAB619;
    border: 1px solid #FAB619;
}