.form {
    display: flex;
    flex-direction: column;
    padding: 0 36px;
    position: relative;
    @media (max-width: 460px) {

        padding: 0;
    }
}

.form__close {
    position: absolute;
    fill: var(--link-contact);
    cursor: pointer;
    right: 0;
}

.form__title {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 12px;
}

.form__descr {
    font-size: 18px;
    opacity: 0.8;
    margin-bottom: 36px;
}

.form__form {
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.error {
    position: absolute;
    font-size: 10px;
    color: red;
}

.form__label {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 6px;
    align-items: flex-start;
    > span {
        > span {
            color: red;
        }
    }
    > .error {
        top: 10px;
        right: 0;
    }
}

.form__labelCheckbox {
    position: relative;
    display: flex;
    gap: 6px;
    align-items: center;
    cursor: pointer;
    font-size: 12px;
    > .error {
        bottom: -20px;
        left: 0;
    }
}


.form__input {
    width: 100%;
    padding: 12px 12px;
    outline: none;
    border: none;
    font-size: 18px;
    border-radius: 10px;
    background-color: var(--white-hover);
    color: var(--black);
}

.form__link {
    color: var(--link-contact);
}

.form__btn {
    margin-top: 24px;
    width: 100%;
    background-color: var(--white);
    border: 2px solid var(--link-contact);
    color: var(--link-contact);
    padding: 12px;
    border-radius: 10px;
    outline: none;
    transition: background-color 0.3s linear;

    &:hover {
        background-color: var(--white-hover);
    }
}