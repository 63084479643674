:root {
  --black: #000;
  --white: #ececec;
  --white-hover: #cfcfcf;
  --accent: rgb(180, 0, 255);
  --secondary: rgb(25, 195, 230);
  --gray: #333;
  --link: #000;
  --link-contact: rgb(25, 111, 230);
  --link-hover: rgb(180, 0, 255);
  --bg-footer: #0F1927;
  --shadow: 0px 14px 39px -11px rgba(0, 0, 0, 0.06);

  --primary-color: #04ff04;
  --overlay-color: rgba(0, 0, 0, 0.7); 
}
