.headerVideo {
    padding: 12px 0;
    margin-bottom: 60px;
}


.video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;

}