.layout {
  margin: 0 auto;
  max-width: 1240px;
  padding: 0px 20px;
  background-color: transparent;

  @media (max-width: 460px) {
    padding: 0;
  }
}

