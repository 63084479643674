.services {
    padding: 60px 0;
    display: flex;
    flex-direction: column;
    gap: 192px;
    @media (max-width: 768px) {
        gap: 96px;
    }
    @media (max-width: 460px) {
        padding: 0 20px;
    }
}

.services__block {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 48px;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
}

.services__left {
    display: flex;
    flex-direction: column;
}

.services__title {
    font-size: 32px;
    margin-bottom: 24px;
    color: var(--black);
    @media (max-width: 460px) {
        font-size: 22px;
    }
}

.services__text {
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
    color: var(--black);
    text-align: justify;
    -webkit-hyphens: auto;
// hyphens: auto;
}

.services__list {
    display: flex;
    flex-direction: column;
    gap: 6px;
    list-style: disc;
    padding-left: 32px;
}

.services__item {
    text-align: justify;
    -webkit-hyphens: auto;
hyphens: auto;
}

.services__right {
    overflow: hidden;
    border-radius: 12px;
    display: flex;

    img {
        border-radius: 12px;
        width: 100%;
        object-fit: cover;
    }
}