.slide {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;

    > h3 {
        text-align: center;
    }
}
