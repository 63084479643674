html {
  box-sizing: border-box;
}

*,
::before,
::after {
  box-sizing: inherit;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  max-width: 100%;
}

h1,
h2,
h3,
p {
  margin: 0;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

button {
  padding: 0;
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
}