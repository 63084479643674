.parthner {
    display: flex;
    flex-direction: column;
    width: 100%;
    @media (max-width: 460px) {
        padding: 0 20px;
    }
}

.parthner__title {
    font-size: 40px;
    font-weight: 600;
    color: var(--black);
    margin-bottom: 36px;

    @media (max-width: 475px) {
        font-size: 24px;
    }
}

.parthner__subtitle {
    font-size: 18px;
    font-weight: 600;
    color: var(--black);
    margin-bottom: 16px;
    opacity: 0.9;
}

.parthner__text {
    font-size: 16px;
    font-weight: 400;
    color: var(--black);
    margin-bottom: 24px;
    opacity: 0.85;
    text-align: justify;
    -webkit-hyphens: auto;
hyphens: auto;
    .parthner__text {
        padding-left: 12px;
        &:first-child {
            padding-top: 24px;
        }
    }
}