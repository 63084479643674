.footer__block {
	display: flex;
	flex-direction: column;

	@media (max-width: 1000px) {
		&:first-child {
			grid-column: 1 / 3;
		}

		&:nth-child(2) {
			grid-column: 1 / 3;
			grid-row: 2 / 3;
			justify-self: flex-end;

		}

		&:nth-child(3) {
			grid-column: 3 / 4;
			grid-row: 1 / 3;
			align-self: center;
			justify-self: flex-end;
		}
	}

	@media (max-width: 668px) {
		&:first-child {
			grid-column: 1 / 3;
		}

		&:nth-child(2) {
			grid-column: 2 / 3;
			grid-row: 2 / 3;
			align-self: flex-start;
			justify-self: center;

		}

		&:nth-child(3) {
			grid-column: 1 / 2;
			grid-row: 2 / 3;
			align-self: flex-start;
			justify-self: center;
		}
	}

	@media (max-width: 450px) {

		&:nth-child(2) {
			grid-column: 1 / 3;
			grid-row: 2 / 3;
			justify-self: flex-start;
			h2,
			button {
				display: none;
			}

		}

		&:nth-child(3) {
			justify-self: flex-start;
			grid-column: 1 / 3;
			grid-row: 3 / 4;

		}


		.footer {
			&__link {
				font-size: 12px;
			}

			&__button {
				position: absolute;
				top: 16px;
				right: 0;
			}

			&__text {
				margin: 0;
				font-size: 12px;
			}
		}
	}

	// &_main {
	// 	max-width: 90%;
	// }

	// @media (max-width: 403px) {
	// 	&:first-child {
	// 		grid-column: 1 / 2;
	// 	}

	// 	&:last-child {
	// 		grid-column: 1 / 2;
	// 		grid-row: 3 / 4;
	// 	}

	// 	&:nth-last-child(2) {
	// 		grid-column: 1 / 2;
	// 	}

	// }
}

.footer {
	&__text {
		font-weight: 400;
		font-size: 16px;
		text-align: justify;
	}

	&__subscribe {
		margin-bottom: 14px;
		font-weight: 400;
		font-size: 12px;
	}

	&__title {
		margin-bottom: 25px;
		font-weight: 600;
		font-size: 20px;
	}

	&__link {
		font-weight: 400;
		font-size: 14px;
	}

	&__connection {
		margin: 20px 0 15px;
		font-weight: 400;
		font-size: 14px;
	}

	&__social {
		display: flex;
		flex-direction: row;
		gap: 10px;

		&__link {
			display: flex;
			align-items: center;
			gap: 6px;
		}
	}

	&__phone,
	&__mail {
		display: flex;
		flex-direction: column;
		gap: 5px;
		margin-bottom: 25px;

		span {
			font-weight: 300;
			font-size: 12px;
		}

		span:first-child {
			font-weight: 600;
			font-size: 14px;
		}

		span:nth-child(3) {
			text-decoration: underline;
		}
	}

	&__cards {
		display: flex;
		align-items: center;
		gap: 5px;

		>div {
			display: flex;
			border: 1px solid white;
		}

		> .footer__pay {
			margin-left: 8px;
			transition: opacity 0.3s ease-in-out;

			&:hover {
				opacity: 0.7;
			}
		}
	}

}

.footer__bottom {
	margin-top: 24px;
	padding: 24px 0;
	display: flex;
	align-items: center;
	gap: 48px;
	border-top: 2px solid var(--white);
	justify-content: center;

	@media (max-width: 1024px) {
		flex-direction: column;
	}
}

.footer__menu {
	display: flex;
	gap: 24px;
	align-items: center;
	justify-content: space-around;
}


@media (max-width: 804px) {
	.footer__link {
		font-size: 12px;
	}
}

@media (max-width: 709px) {
	.footer__menu {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
}

@media (max-width: 460px) {
	.footer__menu {
		grid-template-columns: 1fr;
	}
}

.footerOutside {
	padding-top: 12px;
	border-top: 1px solid var(--white);
	display: flex;
	justify-content: center;
	font-size: 14px;
}

.footer__btn {
	align-self: flex-start;
	font-size: 12px;
	color: var(--white);
	padding: 6px 12px;
	border: 1px solid var(--white);
	border-radius: 12px;
}